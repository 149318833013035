import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const learnqs = () => {
    return (
      <Layout>
         <Seo
        title="Learn - Quicksticks"
        description="Learn about how to use your quicksticks as efficiently and as long as possible."/>
        <div className="flex flex-col lg:flex-row justify-center items-center lg:h-screen">
          <h1 className="lg:w-1/2 text-right text-4xl lg:text-6xl font-semibold mx-5 pt-20">Learn more<br/> about quicksticks</h1>
          <div className="lg:w-1/2 pt-10 lg:pt-0">
            <p className="xl:w-3/4 mx-5">
            We are in the works on a proper documentation and tips and tricks for our slate with detailed videos and helpful information to use your quicksticks as efficiently and as long as possible.<br/>
            For now, here are a few tips to get going:
            <br/><br/>  
            <b>1. Adjust the friction to your liking</b><br/>
            When first getting your slate, we recommend to adjust the friction on the slate mechanism to a tightness you enjoy. <br/>
            To do that, take a slot screwdriver and tighten both screws on either side of the sticks to a point you would consider it to be a bit too stiff for your liking. Make sure you tighten both screws similarly. Then loosen both screws by a small amount (less then an eight of a turn) to get a good friction.<br/>
            Using to little friction may lead to more side wobble on the sticks.
            <br/><br/>
            <b>2. Cleaning the plate</b><br/>
            To clean the plate of your quicksticks, you can use the felt on the back of the included marker for everyday occasions.<br/>
            If stains remain on the surface which don't go away with a microfiber cloth, use water and Isopropyl alcohol in combination with a soft or microfiber cloth to rub on the dirty surface.<br/>
            Please don't use spirits or other harsh cleaning products, as they may damage the surface of the acrylic.<br/><br/>
            Pro tip: It may happen that someone takes a permanent marker and uses it on the plate. Don't worry, you can quickly remove it by writing with the whiteboard marker on it, letting it soak in for a few seconds, and then rubbing it away with a cloth.
            <br/><br/>
            <b>3. Safely removing and reinserting the plate</b><br/>
            The quickrelease mechanism is made to hold the plate tightly. When removing the plate, it may happen that you pull strongly and may hurt yourself or others with that force.<br/>
            To avoid injuries and damage, put your thumb at one of the corners where the plate and the sticks meet. Grip the plate with your hand, and then push the sticks with your thumb away gently. This requires a lot less energy and is much safer for you and your surrounding.
            </p>
          </div>
        </div>
      </Layout>
  )
}

export default learnqs